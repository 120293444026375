//
//
//
//
//

import * as gtmTag from "~/modules/gtm-script-tag";
export default {
  mounted () {
    this.$nextTick(()=>{
      // add GTM tag
      this.$addGtmScriptToHead(gtmTag.gtmScript);
      this.$addGtmNoScriptToBody(gtmTag.gtmNoScript);
      
    })
  },
}
