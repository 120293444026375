import jq from "jquery";
export default ({ app }, inject) => {
    inject('addAWtag', function(jsScript){
        var script = document.createElement("script");
        script.innerHTML = jsScript;
        document.head.appendChild(script);
    }),
    inject('checkUrlData', function(urlData){
        let hash = this.$route.query[urlData];
        // console.log(hash);
        return hash;
    }),
    inject('addGtmScriptToHead', function(scriptData){
        var script = document.createElement("script");
        script.innerHTML = scriptData;
        document.head.appendChild(script);
    }),
    inject('addGtmNoScriptToBody', function(noScriptData){
        var noScript = document.createElement("noscript");
        var noScriptIframe = document.createElement("iframe");
        noScriptIframe.width = 0;
        noScriptIframe.height = 0;
        noScriptIframe.style.display = "none";
        noScriptIframe.style.visibility = "hidden";
        noScriptIframe.src = noScriptData;

        noScript.appendChild(noScriptIframe);
        // document.body.appendChild(noScript);
        document.body.insertBefore(noScript, document.body.firstChild);
        console.log("GTM tag added");
    }),
    inject('loadScript', function(FILE_URL, async = true, type = "text/javascript"){
        return new Promise((resolve, reject) => {
            try {
                const scriptEle = document.createElement("script");
                scriptEle.type = type;
                scriptEle.async = async;
                scriptEle.src = FILE_URL;

                scriptEle.addEventListener("load", (ev) => {
                    resolve({ status: true });
                });

                scriptEle.addEventListener("error", (ev) => {
                    reject({
                        status: false,
                        message: `Failed to load the script ${FILE_URL}`
                    });
                });

                document.body.appendChild(scriptEle);
            } catch (error) {
                reject(error);
            }
        });
    }),
    inject('addAWtag', function(jsScript){
        var script = document.createElement("script");
        script.innerHTML = jsScript;
        document.head.appendChild(script);
    })
}