// //Google Tag Manager
// (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
// new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
// j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
// 'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
// })(window,document,'script','dataLayer','GTM-T485T8T');
// //End Google Tag Manager

//Google Tag Manager (noscript)
{/* <noscript>
    <iframe src="https://www.googletagmanager.com/ns.html?id=GTM-T485T8T" height="0" width="0" style="display:none;visibility:hidden"></iframe>
</noscript> */}
//End Google Tag Manager (noscript)


// Google Tag Manager created on Aug 26, 2024 -->
var gtmScript = `(function(w, d, s, l, i) {
    w[l] = w[l] || [];
    w[l].push({
        'gtm.start': new Date().getTime(),
        event: 'gtm.js'
    });
    var f = d.getElementsByTagName(s)[0],
        j = d.createElement(s),
        dl = l != 'dataLayer' ? '&l=' + l : '';
    j.async = true;
    j.src =
        'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
    f.parentNode.insertBefore(j, f);
})(window, document, 'script', 'dataLayer', 'GTM-M87FP6SH')`;
// End Google Tag Manager -->


// Google Tag Manager (noscript)
var gtmNoScript = `https://www.googletagmanager.com/ns.html?id=GTM-M87FP6SH`
// End Google Tag Manager (noscript)

export {gtmScript, gtmNoScript};